<template>
  <div class="page">
    <h1><w-icon>mdi mdi-lightbulb-on-outline</w-icon> わけりんぐ とは？</h1>
    <div class="content">
      <p class="center">
        暗記を 楽しむ
        <w-icon>mdi mdi-tag-heart-outline</w-icon>
      </p>
      <w-image tag="img" width="100%" style="max-width: 600px" class="bdrs5" src="/image/page/item/wake-ring/overview/top.jpg"></w-image>
      <p>
        &emsp;<b>わけりんぐ</b>は、暗記を楽しむための
        まったく新しい 画期的な発明作品です。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/merit/">仕組みと効果</router-link>
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/research/">有効性の調査</router-link>
      </p>
      <w-image tag="img" width="100%" style="max-width: 600px" class="bdrs5" src="/image/page/item/wake-ring/overview/3up.jpg"></w-image>
      <p>
        &emsp;どなたでも簡単に扱うことができます。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/how-to-use/">シミュレーション</router-link>
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/movie/">詳しい動画</router-link>
      </p>
      <w-image tag="img" width="100%" style="max-width: 600px" class="bdrs5" src="/image/page/item/wake-ring/overview/present.jpg"></w-image>
      <p>
        &emsp;いまなら、お好きな学習カードをプレゼント中です。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <a href="https://minne.com/items/24642656" target="_blank" rel="noopener noreferrer">
          ショップ
          <w-icon>mdi mdi-open-in-new</w-icon>
        </a>
      </p>
      <p>
        &emsp;苦手な暗記は、皆で楽しみながら 克服していきましょう
        <w-icon>mdi mdi-owl</w-icon>
      </p>
      <p><w-divider/></p>
      <div class="next-link">
        <w-flex align-center justify-space-evenly>
          <router-link to="/item/wake-ring/merit/">仕組みとメリット</router-link>
          <router-link to="/item/wake-ring/how-to-use/">ＣＧシミュレーション</router-link>
          <router-link to="/item/wake-ring/movie/">動画</router-link>
          <router-link to="/item/wake-ring/research/">有効性</router-link>
          <a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a>
        </w-flex>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
